.home {
    .home-profile {
      @include transform(translateY( 16vh));
      padding: 0 0 .5rem;
      text-align: center;
  
      .home-avatar {
        padding: .5rem;
  
        img {
          display: inline-block;
          width: 20rem;
          height: auto;
          margin: 0 auto;
          
          @include border-radius(0%);
          @include box-shadow(0 0 0 0em rgba(0, 0, 0, .05));
          @include transition(all 0.4s ease);
          
          &:hover {
            position: relative;
            @include transform(translateY(-.75rem));
          }
        }
      }
    }
}